$mobile-s: 320px;
$mobile-sm: 340px;
$mobile-m: 375px;
$mobile-ml: 413px;
$mobile-l: 479px;
$tablet: 768px;
$laptop: 1024px;

@mixin collapse($line) {
  display: -webkit-box;
  overflow: hidden;
  max-height: none;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
}

@mixin flex($align-item, $justify-content) {
  display: flex;
  align-items: $align-item;
  justify-content: $justify-content;
}

@mixin respond($breakpoint) {
  @if $breakpoint == mobile-s {
    @media (max-width: $mobile-s) {
      @content;
    }
  }
  @if $breakpoint == mobile-sm {
    @media (max-width: $mobile-sm) {
      @content;
    }
  }
  @if $breakpoint == mobile-m {
    @media (max-width: $mobile-m) {
      @content;
    }
  }
  @if $breakpoint == mobile-ml {
    @media (max-width: $mobile-ml) {
      @content;
    }
  }
  @if $breakpoint == mobile-l {
    @media (max-width: $mobile-l) {
      @content;
    }
  }
  @if $breakpoint == tablet {
    @media (max-width: $tablet) {
      @content;
    }
  }
  @if $breakpoint == laptop {
    @media (max-width: $laptop) {
      @content;
    }
  }
  @if $breakpoint == laptop-l {
    @media (min-width: $laptop) {
      @content;
    }
  }
}

