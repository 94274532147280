.container {
  .title {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #3B3B3B;
    margin-right: 5px;
  }
  .required {
    color: #FF0000;
  }
}