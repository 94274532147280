@import "/src/styles/variables";
@import "styles/_mixin.scss";

.side_nav {
  box-shadow: 0px 100px 80px rgba(205, 205, 205, 0.1),
    0px 41.7776px 33.4221px rgba(205, 205, 205, 0.0718854),
    0px 22.3363px 17.869px rgba(205, 205, 205, 0.0596107),
    0px 12.5216px 10.0172px rgba(205, 205, 205, 0.05),
    0px 6.6501px 5.32008px rgba(205, 205, 205, 0.0403893),
    0px 2.76726px 2.21381px rgba(205, 205, 205, 0.0281146);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
  grid-column: 1 / span 1;
  background: #fff;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  @include respond(mobile-l) {
    display: none;
  }

  :global {
    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
      border: none;
    }
    .ant-tooltip-inner > a {
      color: #fff !important;
    }

    .ant-layout-sider {
      background-color: #fff;
    }

    .ant-layout-sider-trigger {
      background-color: #2481df;
    }

    .ant-menu-submenu-selected .ant-menu {
      background-color: $color-seagull;
    }
    .ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
      background-color: $color-seagull;
      color: $color-white;
    }
    .ant-menu-submenu-selected
      .ant-menu
      .ant-menu-item-only-child.ant-menu-item-selected {
      background-color: $color-seagull;
    }
    .ant-menu-submenu-selected
      .ant-menu
      .ant-menu-item-only-child.ant-menu-item-selected {
      color: $color-seagull;
    }

    .ant-menu {
      color: $color-black;
    }
    .ant-menu-item a {
      color: $color-black !important;
    }
    .ant-menu-item-selected a {
      color: $color-white !important;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: $color-seagull;
    }
    .ant-menu-item:hover a {
      color: $color-white !important;
    }
    .ant-menu-submenu-title,
    .ant-menu-item {
      display: flex;
      align-items: center;
    }
    .ant-menu-item-icon {
      min-width: 20px !important;
    }
  }

  &__sider {
    flex-grow: 1 !important;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 16px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      height: 56px;
      border-radius: 8px;
      border: 4px solid transparent;
      background-clip: content-box;
      background-color: #b8b8b8;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #b8b8b8;
    }
  }
}

.logo_container {
  height: $header-height;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 160px;
  height: 100%;
  transition: all 0.3s ease 0s;
}

.contMenuItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: $color-white;
  color: $color-black;
  padding: 15px 10px;
  &__selected {
    @extend .contMenuItem;
    background-color: $color-scooter;
    color: $color-white;
  }
}
.contMenuItem:hover {
  background-color: $color-scooter;
  color: $color-white;
}

.badge {
  margin-right: 18px;
}

.badgeRound {
  :global {
    .ant-badge-count {
      width: 20px;
      height: 20px;
      line-height: 20px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
  }
}
