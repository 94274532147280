.container {
  .header {
    padding: 15px;
    border: 0.5px solid #d9d9d9;
    border-radius: 10px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .back {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #3b3b3b;
      svg {
        margin-right: 10px;
        cursor: pointer;
      }
    }
    .actions {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 25px;
      color: #3b3b3b;
    }
  }
  .content {
    margin-top: 12px;
    background: #ffffff;
    border: 0.5px solid #d9d9d9;
    border-radius: 10px;
  }
}

.wrapContainerTitleTable {
  margin-top: 40px;
  display: flex;
  flex: 1;
  background-color: #87d6ea;
  padding: 10px 0px;
}
.wrapColumnOne {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
  font-size: 18px;
  font-weight: 500;
  color: white;
}
.wrapColumnTwo {
  display: flex;
  flex: 3;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
  font-size: 18px;
  font-weight: 500;
  color: white;
}
.wrapColumnThree {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
  font-size: 18px;
  font-weight: 500;
  color: white;
}

.wrapListExp {
  padding: 10px;
}

.wrapNoHistory {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  padding: 30px 0px;
  color: black;
}
