@import "../../styles//variables";

.uploadCustom {
  :global {
    .ant-upload.ant-upload-select {
      width: 100% !important;
      background-color: $color-white !important;
      padding: 15px !important;
      margin-inline-end: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}

.uploadContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  color: $color-black;
  font-weight: 700;
  .imgUploadImage {
    margin-bottom: 12px;
  }
}

.txtLabel {
  color: $color-black;
  font-size: 14;
  font-weight: 700;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  &__text {
    margin-right: 5px;
  }
}

.txtDescription {
  color: $color-silver;
  font-size: 12px;
}

.txtError {
  color: $color-red;
  font-size: 12px;
}
