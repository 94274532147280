.userFilter {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
  .searchFilter,
  .statusFilter {
    margin-right: 10px;
    .filterLabel {
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
    }
  }
  .searchFilter {
    .inputSearch {
      width: 388px;
      margin-top: 5px;
    }
  }
  .statusFilter {
    .selectStatus {
      width: 189px;
      margin-top: 5px;
    }
  }
  .searchBtn {
    width: 150px;
  }
}
