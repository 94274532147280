.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .header {
    padding: 20px 15px;
    background: #ffffff;
    border: 0.5px solid #d9d9d9;
    border-radius: 10px;
    .contTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #3b3b3b;
      }
      .addBtn {
        width: 177px;
      }
    }
  }
  .content {
    flex: 1;
    width: 100%;
    padding: 0px 15px;
    padding-top: 30px;
    background: #ffffff;
    border: 0.5px solid #d9d9d9;
    border-radius: 10px;
  }
}

.btnSubmit {
  width: 150px;
  margin: 30px auto 0px auto;
}
