
$header-height: 60px;
$side-nav-width: 250px;

$primary-color: #1d0157;
$white-color: #fff;
$gray-color: #eeeeee;
$total-title-color: #a29490;
$dark-red-color: #a61f17;
$header-table-color: rgba($primary-color, 0.25);
$text-table-color: #4f4f4f;
$large-fontsize: 18px;
$color-gray: #909090;
$color-gray-2: #d6d6d6;
$color-snow: #f9f9f9;
$color-silver: #c4c4c4;
$color-alto: #d9d9d9;
$color-alabaster: #f8f8f8;
$color-white: #ffffff;
$color-black: #3b3b3b;
$color-red: #ff0000;
$color-scarlet: #ff1717;
$color-red-2: #e33636;
$color-scooter: #22bbdf;
$color-lavender: #ffe9f0;
$color-golden: #feef2e;
$color-punch: #d95225;
$color-transparent: transparent;
$color-primary: #ffe9f0;
$color-seagull: #87d6ea;
$color-tickle-pink: #f971a5;
$color-emperor: #535353;
$color-blue-light: #e7f8ff;
$color-blue-white: #f4fcff;
$color-trita: #bce4ed;
