.container {
  background: #ffffff;
  border: 0.5px solid #d9d9d9;
  border-radius: 10px;
  padding: 20px 15px;
  .topHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #3b3b3b;
  }
  .header {
    // padding: 15px;
    // border: 0.5px solid #d9d9d9;
    border-radius: 10px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .back {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #3b3b3b;
      svg {
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
}

.wrapRowEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
  margin-right: 25px;
  margin-bottom: 20px;
}

.wrapRowFilter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
  position: relative;
  padding-right: 15px;
  position: relative;
}

.containerTable {
  width: calc(100vw - 30px);
  margin-top: 20px;
  overflow-x: hidden;
}

.wrapTable {
  margin-top: 36px;
}

.wrapIconNext {
  height: 12px;
  aspect-ratio: cos(30deg);
  clip-path: polygon(0 0, 100% 50%, 0 100%);
  background: linear-gradient(45deg, #d9d9d9, #d9d9d9);
  cursor: pointer;
  margin-left: 5px;
  position: absolute;
  right: 0;
}

/* HTML: <div class="triangle"></div> */
.wrapIconBack {
  height: 12px;
  aspect-ratio: cos(30deg);
  clip-path: polygon(100% 0, 0 50%, 100% 100%);
  background: linear-gradient(45deg, #d9d9d9, #d9d9d9);
  cursor: pointer;
  margin-right: 5px;
}

.titleAmount {
  font-size: 18px;
  font-weight: 500;
  color: black;
}

.titleMiddle {
  margin-top: 44px;
  .wrapNameTitle {
    margin-bottom: 19px;
    font-size: 24px;
    font-weight: 700;
  }
  .wrapNameAgencyTitle {
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: 700;
  }
  .wrapRowTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .wrapAmountContainer {
    border-bottom: 1.5px #000 solid;
    .wrapAmount {
      font-size: 24px;
      font-weight: 500;

      .amount {
        font-weight: 700;
        padding-left: 20px;
      }
      .tax {
        font-size: 13px;
        font-weight: 300;
        margin-left: 10px;
      }
    }
  }
}

.wrapButtonDownLoad {
  border-radius: 10px;
  border: 1.5px black solid;
  background-color: #d9d9d9;
  width: 123px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
}

.wrapTotalAmount {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 400;
  width: 180px;
}
.wrapAmountValue {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
  width: 180px;
}

.wrapRow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
}
