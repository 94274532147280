.btnCustom {
  :global {
    .ant-btn {
      background: #87d6ea;
      border-radius: 50px;
      min-height: 45px;
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: #ffffff;
      width: 100%;
      justify-content: center;
      padding: 12px 20px;
      border-color: #87d6ea;
    }
  }
}

.btnOutline {
  :global {
    .ant-btn {
      border: 1px solid #87d6ea;
      background: #fff;
      color: #87d6ea;
    }
  }
}

.btnAction {
  :global {
    .ant-btn {
      width: 40px;
      height: 40px;
      border-radius: 10px;
    }
  }
}
