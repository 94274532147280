@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;500;700;800;900&family=Noto+Sans+JP:wght@400;500;700;900&display=swap");
@import "./custom";
@import "./variables";
@import "./general";
@import "antd/dist/reset.css";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "M PLUS Rounded 1c", sans-serif;
  // overflow: hidden;
  color: $color-black;
}
.ant-table {
  font-family: "M PLUS Rounded 1c", sans-serif !important;
}
.ant-select-selector {
  font-family: "M PLUS Rounded 1c", sans-serif !important;
}
.ant-dropdown-menu {
  font-family: "M PLUS Rounded 1c", sans-serif !important;
}
.ant-input {
  font-family: "M PLUS Rounded 1c", sans-serif !important;
}
.ant-btn {
  font-family: "M PLUS Rounded 1c", sans-serif !important;
}
.ant-modal {
  font-family: "M PLUS Rounded 1c", sans-serif !important;
}
.ant-radio-wrapper {
  font-family: "M PLUS Rounded 1c", sans-serif !important;
}
.ant-form {
  font-family: "M PLUS Rounded 1c", sans-serif !important;
}

.ant-form .ant-form-item .ant-form-item-label {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  padding-bottom: 5px;
  label {
    height: unset;
  }
}

.input {
  padding: 10px 10px !important;
  border-radius: 10px !important;
  font-size: 16px;
  .ant-input {
    padding: 10px 10px !important;
    border-radius: 10px !important;
  }
}

.inputNumber {
  padding: 10px 10px !important;
  border-radius: 10px !important;
  font-size: 16px;
}
.ant-input-number {
  padding: 10px 10px !important;
  border-radius: 10px !important;
  width: 100%;
}

.ant-picker {
  padding: 10px 10px !important;
  border-radius: 10px !important;
}
.ant-input-affix-wrapper {
  padding: 0px 11px !important;
  border-radius: 10px !important;
}

.ant-input-affix-wrapper {
  .ant-input {
    border-radius: 0px !important;
  }
}

.ant-tree-select-dropdown .ant-select-tree .ant-select-tree-switcher-noop,
.ant-tree-select-dropdown .ant-select-tree .ant-select-tree-switcher {
  display: none;
}
.ant-tree-select-dropdown {
  padding: 20px 16px;
}

.select {
  .ant-select-selector {
    min-height: 44px !important;
    display: flex;
    align-items: center;
    border-radius: 10px !important;
  }
}

.tree-select {
  .ant-select-selector {
    min-height: 44px !important;
    display: flex;
    align-items: center;
    border-radius: 10px !important;
  }
  .ant-select-selection-item {
    background: #ffe9f0;
    height: 29px;
    border-radius: 50px;

    display: flex;
    align-items: center;
  }
  .ant-select-selection-item-remove {
    width: 16px;
    height: 16px;
    background: $color-tickle-pink !important;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .ant-select-tree-switcher-icon {
    display: none;
  }
}

.ant-form-item-required {
  flex-direction: row-reverse;
  align-items: flex-start !important;
}

.ant-form-item-label {
  font-size: 14px;
  font-weight: 700 !important;
}

.ant-checkbox-input {
  width: 20px !important;
  height: 20px !important;
}

.ant-checkbox-inner {
  width: 20px !important;
  height: 20px !important;
  border: 1px solid $color-black !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
  background-color: $color-tickle-pink !important;
  border-color: $color-tickle-pink !important;
}

.ant-checkbox-wrapper {
  font-size: 14px;
  font-weight: 400;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $color-tickle-pink !important;
  border-color: $color-tickle-pink !important;
}

.ant-checkbox-checked:after {
  border-color: $color-tickle-pink !important;
}

.ant-tooltip {
  .ant-tooltip-inner {
    background-color: $color-white;
    color: $color-black;
    box-shadow: grey 0px 1px 10px;
    min-height: unset;
  }
  --antd-arrow-background-color: $color-white;
}

.ant-slider-tooltip {
  .ant-tooltip-inner {
    background-color: $color-tickle-pink;
    color: $color-white;
    padding: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
  .ant-tooltip-arrow::before {
    background-color: $color-tickle-pink;
  }
}

.btn {
  border-radius: 50px;
  height: 45px !important;
  font-weight: 700;
}

@for $i from 1 through 6 {
  .col-#{$i} {
    & > *:not(:nth-child(#{$i}n)) {
      margin-right: 15px;
    }
    & > *:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}

@for $i from 1 through 3 {
  .line-#{$i} {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $i;
    -webkit-box-orient: vertical;
  }
}

input::placeholder {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: $color-silver;
}

.h-100 {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: unset;
}

.radio {
  width: 100%;
  .ant-radio-inner {
    border-color: $color-black;
    // background-color: #f971a5;
  }
  .ant-radio-checked {
    .ant-radio-inner {
      border-color: $color-tickle-pink;
      background-color: $color-white;
    }
    .ant-radio-inner::after {
      background-color: $color-tickle-pink;
    }
  }
}
.radio-row-2 {
  .ant-radio-wrapper {
    width: calc((100% - 8px * 2) / 2) !important;
  }
}

.radio-row-3 {
  .ant-radio-wrapper {
    width: calc((100% - 8px * 3) / 3) !important;
  }
}

.radio-row-4 {
  .ant-radio-wrapper {
    width: calc((100% - 8px * 4) / 4) !important;
  }
}

.date-picker {
  .ant-picker-suffix {
    color: $color-black;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-unset {
  all: unset;
  &:visited,
  &:active,
  &:hover,
  &:focus {
    all: unset;
  }
}

.ant-input-disabled {
  color: $color-black !important;
}

.ant-select-disabled .ant-select-selector {
  color: $color-black !important;
}

.ant-tabs-nav-operations {
  display: none !important;
}

.container {
  margin-top: 8px;
  background: $color-white;
  border: 0.5px solid $color-alto;
  border-radius: 10px;
  .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-nav-list {
    width: 100%;
    background: #f4fcff;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 10px 10px 0px 0px;
    height: 55px;
  }
  .ant-tabs-tab {
    margin: 0 !important;
    flex: 1;
    max-width: 25%;
    display: flex;
    justify-content: center;
  }
  .ant-tabs-tab-btn {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #909090;
  }
}

.container-override {
  max-width: unset !important;
}

.subTabs {
  .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-nav-list {
    width: 100%;
    background: white;
    border-bottom: 1px solid #d9d9d9;
    height: 45px;
  }
  .ant-tabs-tab {
    margin: 0 !important;
    flex: 1;
    max-width: 25%;
    display: flex;
    justify-content: center;
  }
  .ant-tabs-tab-btn {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
}

.ant-popconfirm .ant-popconfirm-message .ant-popconfirm-message-icon .anticon {
  visibility: hidden;
}

button:not(:disabled):hover {
  background: #87d6ea;
}
//TinyMCE//
.tox-notifications-container {
  display: none !important;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

.wrapOffName {
  width: 14px;
  height: 14px;
  border-radius: 12px;
  background-color: #909090;
  margin-right: 6px;
  margin-left: 2px;
  border: 2px solid white;
}

.wrapOnlName {
  width: 14px;
  height: 14px;
  border-radius: 12px;
  background-color: #00a400;
  margin-right: 6px;
  margin-left: 2px;
  border: 2px solid white;
}

.modalForbidden {
  .ant-modal-body {
    min-height: unset;
  }
}

.radio-big {
  .ant-radio .ant-radio-inner {
    width: 20px !important;
    height: 20px !important;
  }
}
