:global(.modalCustom) {
  top: 30%;

  :global(.ant-modal-body) {
    min-height: 300px;
    height: 40%;
    display: flex;
    flex-direction: column;

    .formContainer {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
    }
  }
}

.btnSubmit {
  width: 150px;
  margin: 30px auto 0px auto;
}

.row {
  display: flex;
  :global {
    .ant-form-item {
      margin-bottom: 0;
      margin-top: 5px;
    }
  }
}

.row + .row {
  margin-top: 20px;
}

.tooltip {
  margin-left: 5px;
}

.tooltipModal {
  color: red;
  :global {
    .ant-tooltip-inner {
      white-space: pre-line;
    }
  }
}

.warning {
  color: #ff0000;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.note {
  color: #909090;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
