.flex {
  display: flex;
}

.flex-center {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.f-3 {
  flex: 3;
}

.f-2 {
  flex: 2;
}

.f-1 {
  flex: 1;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.p-2 {
  padding: 2px 5px;
}

//margin
.mt-4 {
  margin-top: 4px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-8 {
  margin-left: 8px;
}

.text-ali-center {
  text-align: center;
}

//width
.w-50p {
  width: 50%;
}
