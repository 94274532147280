.planFilter {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;

  :global {
    .ant-form-item {
      margin: 0;
      padding: 0;
    }
  }
  .searchFilter,
  .durationFilter,
  .dateStartFilter,
  .dateEndFilter {
    margin-right: 10px;
    .filterLabel {
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
    }
  }
  .searchFilter {
    .inputSearch {
      width: 189px;
      margin-top: 5px;
    }
  }
  .durationFilter {
    .selectDuration {
      width: 189px;
      margin-top: 5px;
    }
  }
  .searchBtn {
    width: 150px;
  }
  .dateStartFilter {
    .inputStartDate {
      width: 189px;
      margin-top: 5px;
    }
  }
  .dateEndFilter {
    .inputEndDate {
      width: 189px;
      margin-top: 5px;
    }
  }
  .searchBtn {
    width: 150px;
  }
  .and {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #3b3b3b;
  }
}
