@import "/src/styles/variables";
.icon {
  // width: 30px;
  // height: 30px;
  &__detail {
    color: #df2115;
    font-size: 18px;
    padding: 2px;
    margin-right: 4px;
  }
  &__detail_block {
    color: #df2115;
    font-size: 20px;
    padding: 2px;
    margin-right: 4px;
  }
}
