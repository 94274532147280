@import "./variables";

.inputCustom {
  background: #ffffff;
  border: 0.5px solid #d6d6d6;
  border-radius: 10px;
  min-height: 44px;
  font-size: 16px;
  width: 100%;
  .ant-input-prefix {
    margin-right: 10px;
  }
  ::placeholder {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: $color-silver;
  }
  input::placeholder {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: $color-silver;
  }
  &:focus-visible {
    border-color: #b7edf7;
  }
  &.ant-input-number .ant-input-number-input {
    height: 44px !important;
  }
}

.numberFormat {
  box-sizing: border-box;
  margin: 0;
  padding: 10px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;

  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 10px;
  transition: all 0.2s;
  &:hover {
    border-color: #b7edf7;
    border-inline-end-width: 1px;
  }
  &:focus {
    border-color: #b7edf7;
    box-shadow: 0 0 0 2px rgba(5, 238, 255, 0.06);
    border-inline-end-width: 1px;
    outline: 0;
  }
}

.selectCustom {
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #3b3b3b;
  .ant-select-selector {
    min-height: 44px !important;
    input {
      height: 100% !important;
    }
    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }
  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }
}

.paginationCustom {
  display: flex;
  align-items: center;
  .ant-pagination-item-active {
    background: #87d6ea !important;
    a {
      color: #f6f6f6;
    }
    &:hover a {
      color: #f6f6f6;
    }
  }
  .ant-pagination-item {
    width: 30px;
    height: 30px;
    background: #ebebeb;
    border-radius: 50%;
    border: none;
    a {
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
  .ant-pagination-total-text {
    flex: 1;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #3b3b3b;
    display: flex;
    align-items: center;
  }
}

.datePickerCustom {
  width: 100%;
  :global {
    input {
      &::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #c4c4c4;
      }
    }
  }
}

.tableCustom {
  ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 20px;
  }
  table {
    border-radius: 0px 0px 10px 10px;
  }
  .ant-table-thead > tr > th {
    padding: 3.5px 16px;
    background: #fff;
    height: 50px;
    color: $primary-color;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #3b3b3b;
  }
  .ant-table-tbody > tr > td {
    box-sizing: border-box;
    padding: 10px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #3b3b3b;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan]):before {
    display: none;
  }
  .ant-table-container table > thead > tr:first-child > *:last-child {
    border-start-start-radius: 10px !important;
  }
  .ant-table-container table > thead > tr:first-child > *:first-child {
    border-start-start-radius: 10px !important;
  }
  .ant-pagination-item-active {
    background-color: $primary-color !important;
  }
  .ant-pagination-item-active a {
    color: $white-color;
    font-weight: bold;
  }
  .ant-pagination-item-link,
  .ant-pagination-item {
    border: none;
    background-color: #eeeeee;
    border-radius: 4px;
  }

  .ant-table {
    border-radius: 10px;
  }
  &--border {
    table {
      border: 0.5px solid #d9d9d9;
      border-radius: 10px;
    }
  }
}

.modalCustom {
  .ant-modal-content {
    padding: 0;
    .ant-modal-header {
      background: #87d6ea;
      padding: 12px 20px;
      margin-bottom: 0;
      .ant-modal-title {
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      img {
        cursor: pointer;
      }
    }
    .ant-modal-body {
      padding: 30px 20px;
    }
  }
}

.uploadCustom {
  .ant-upload {
    width: 100% !important;
    background: #fff;
    margin: 0;
  }
}

.timePickerCustom {
  width: 100%;
}

.textAreaCustom {
  height: 88px;
  textarea {
    resize: none;
  }
  position: relative;
  white-space: pre-line;

  .ant-input-data-count {
    position: absolute;
    bottom: 30px !important;
    right: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 2 0px;
  }
}

.ant-switch-checked {
  background: #87d6ea !important;
}

.switchCustom {
  // width: 60px;
  // height: 30px;
  // .ant-switch-handle {
  //   width: 28px;
  //   height: 28px;
  //   border-radius: 50%;
  // }
}

.tabCustom {
  .ant-tabs-nav-list {
    flex: 1;
    .ant-tabs-tab {
      flex: 1;
      justify-content: center;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: #909090;
    }
    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: #87d6ea;
      }
    }
    .ant-tabs-ink-bar {
      background: #87d6ea;
    }
  }
}

.timePickerPopup {
  .ant-picker-footer .ant-picker-ranges {
    justify-content: center;
  }
  .ant-picker-ok {
    margin-inline-start: unset !important;
  }
}

.sliderCustom {
  .ant-slider-mark-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    color: #f971a5;
  }
  .ant-slider-track {
    background: #969595;
    border-radius: 10px;
  }
  .ant-slider-handle::after {
    background: #969595;
    box-shadow: 0 0 0 2px #969595;
  }
  .ant-slider-handle:hover,
  .ant-slider-handle:focus {
    &::after {
      background: #3b3b3b;
      box-shadow: 0 0 0 2px #3b3b3b;
    }
  }
  &:hover {
    .ant-slider-track {
      background: #3b3b3b;
    }
    .ant-slider-handle::after {
      background: #3b3b3b;
      box-shadow: 0 0 0 2px #3b3b3b;
    }
  }
}

.radioCustom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .ant-radio-wrapper {
  }
  .ant-radio-checked {
    .ant-radio-inner {
      border-color: $color-tickle-pink;
      background-color: $color-white;
      &::after {
        transform: scale(0.5);
      }
    }
    .ant-radio-inner::after {
      background-color: $color-tickle-pink;
    }
  }
}

.form {
  &__textArea {
    position: relative;

    &::after {
      position: absolute;
      bottom: 22px;
      right: 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
    :global {
      .ant-input {
        border-radius: 10px !important;
        border: 0.5px solid #d9d9d9;
        padding: 12px 10px 13px;
        margin-bottom: 14px;
      }
    }
  }
}

.tree-select {
  .ant-select-selector {
    min-height: 44px !important;
    display: flex;
    align-items: center;
    border-radius: 10px !important;
  }
  .ant-select-selection-item {
    background: #ffe9f0;
    height: 29px;
    border-radius: 50px;

    display: flex;
    align-items: center;
  }
  .ant-select-selection-item-remove {
    width: 16px;
    height: 16px;
    background: $color-tickle-pink !important;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .ant-select-tree-switcher-icon {
    display: none;
  }
}
.tree {
  .ant-select-selector {
    min-height: 44px !important;
    display: flex;
    align-items: center;
    border-radius: 10px !important;
  }
  .ant-select-selection-item-remove {
    width: 16px;
    height: 16px;
    background: $color-tickle-pink !important;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .ant-select-tree-switcher-icon {
    display: none;
  }
}

.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: $color-tickle-pink !important;
  border-color: $color-tickle-pink !important;
}

.text-link {
  color: $color-seagull !important;
}
