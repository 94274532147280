.labelStatus {
  color: #ffffff;
  padding: 3px 12px;
  border-radius: 15px;
  display: inline-block;
  width: 100%;
  text-align: center;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  width: 85%;
  white-space: pre-wrap;
}

.title {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #3b3b3b;
  white-space: pre-wrap;
}
