.container {
  .userHeader {
    background: #ffffff;
    border: 0.5px solid #d9d9d9;
    border-radius: 10px;
    padding: 20px 15px;
    .topHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .title {
      font-weight: 700;
      font-size: 26px;
      line-height: 36px;
      color: #3b3b3b;
    }
    .addStoreBtn {
      width: 177px;
    }
  }
  .storeTable {
    margin-top: 20px;
  }
}

.wrapRowEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
  margin-right: 25px;
  margin-bottom: 20px;
}

.wrapRowFilter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
  position: relative;
}

.containerTable {
  width: calc(100vw - 30px);
  margin-top: 20px;
  overflow-x: hidden;
}

.wrapTable {
  overflow-x: scroll;
}

.wrapIconNext {
  height: 12px;
  aspect-ratio: cos(30deg);
  clip-path: polygon(0 0, 100% 50%, 0 100%);
  background: linear-gradient(45deg, #d9d9d9, #d9d9d9);
  cursor: pointer;
  margin-left: 5px;
  position: absolute;
  right: 0;
}

/* HTML: <div class="triangle"></div> */
.wrapIconBack {
  height: 12px;
  aspect-ratio: cos(30deg);
  clip-path: polygon(100% 0, 0 50%, 100% 100%);
  background: linear-gradient(45deg, #d9d9d9, #d9d9d9);
  cursor: pointer;
  margin-right: 5px;
}

.wrapTable {
  background-color: white;
  border-radius: 15px;
}

.titleAmount {
  font-size: 18px;
  font-weight: 500;
  color: black;
}

.wrapButtonDownLoad {
  border-radius: 10px;
  border: 1.5px black solid;
  background-color: #d9d9d9;
  width: 123px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 40px;
}
