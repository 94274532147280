.btnSubmit {
  width: 150px;
  margin: 30px auto 0px auto;
}

.row {
  display: flex;
  :global {
    .ant-form-item {
      margin-bottom: 0;
      margin-top: 5px;
    }
  }
}

.row + .row {
  margin-top: 20px;
}

.wrapTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #3b3b3b;
  margin-bottom: 8px;
}

.wrapContent {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #3b3b3b;
}
