@import "/src/styles/variables.scss";
@import "styles/_mixin.scss";

.pageWrapper {
  height: 100vh;
  // display: flex;
  // flex-direction: column;
  display: grid;
  grid-template-columns: minmax(#{$side-nav-width}, auto) 1fr;
  @include respond(mobile-l) {
    display: flex;
    grid-template-columns: none;
  }
}
.mainWrapper {
  grid-column: 2 / span 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease 0s;
  min-width: calc(100vw - $side-nav-width);
  @include respond(mobile-l) {
    width: 100%;
  }
}
.pageContent {
  // max-height: calc(100vh - #{$header-height});
  max-height: 100%;
  height: 100%;
  // overflow-x: auto;
  // overflow-y: auto;
  background: #f4f4f4;
  padding: 10px 30px;
  @include respond(mobile-l) {
    padding: 10px 10px;
    max-height: unset;
    height: unset;
  }

  &::-webkit-scrollbar {
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: #b8b8b8;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #b8b8b8;
  }
}
