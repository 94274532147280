.wrapStatus {
  width: 90px;
  height: 42px;
  border: 2px #87d6ea solid;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapOnClick {
  cursor: pointer;
}

.priceCol {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}
