@import "styles/_variables.scss";
.container {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $white-color;
    border: 0.5px solid #d9d9d9;
    border-radius: 10px;
    padding: 15px;
    .back {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #3b3b3b;
      svg {
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
  .content {
    margin-top: 5px;
    background: $white-color;
    border: 0.5px solid #d6d6d6;
    border-radius: 10px;
    padding: 30px 20px;
  }
  .title {
    font-size: 18px;
    font-weight: 700;
  }
  .information {
    margin-top: 16px;
    &__item {
      display: flex;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
    &__label {
      width: 250px;
      flex-shrink: 0;
      font-size: 14px;
    }

    &__value {
      flex-grow: 1;
      font-size: 14px;
      font-weight: 700;
    }
  }
  .relatedFile {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    &__item {
      height: 56px;
      width: 56px;
      border-radius: 5px;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      video {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }
  .replySubtitle {
    font-size: 14px;
    font-weight: 700;
    margin-top: 16px;
  }
  .editor {
    margin-top: 10px;
  }
  .sendBtn {
    width: 165px;
    margin-top: 16px;
  }
}
