@import "styles/_variables.scss";

.container {
  .headerContainer {
    padding: 20px 15px;
    background: #ffffff;
    border: 0.5px solid #d9d9d9;
    border-radius: 10px;
    .postHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #3b3b3b;
      }
      .addStaffBtn {
        width: 177px;
      }
    }
  }
  .storeTable {
    margin-top: 20px;
  }
  .tabs {
    background-color: $color-blue-light;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: $color-gray;
    :global {
      .ant-tabs-nav {
        margin-bottom: 0px;
      }
      .ant-tabs-tab {
        font-weight: 700;
        width: 278px;
        display: flex;
        justify-content: center;
      }
      .ant-tabs-tab + .ant-tabs-tab {
        margin-left: 0px;
      }
    }
  }
}
.tableContainer {
  background: $white-color;
  padding: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  :global {
    .ant-table {
      border: 1px solid $color-alto;
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .ant-table-tbody > tr:last-child > td {
      border-bottom: none !important;
    }
  }
  .pagination {
    border: 1px solid $color-alto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 15px;
  }
}
