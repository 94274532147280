.wrapContainerTitle {
  display: flex;
  flex: 1;
}
.wrapColumnOne {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
}
.wrapColumnTwo {
  display: flex;
  flex: 3;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
  color: #87d6ea;
  cursor: pointer;
  text-decoration: none;
}
.wrapColumnThree {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
}

.wrapListDate {
  border-bottom: 1px solid #ccc;
}

.wrapTitle {
  margin-top: 20px;
}

.wrapContainerTitleTable {
  margin-top: 40px;
  display: flex;
  flex: 1;
  background-color: #87d6ea;
}

.wrapTitleColumn {
  font-size: 18px;
  font-weight: 500;
  color: white;
  padding: 5px 0px;
}
.wrapColumnOne {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
  font-size: 15px;
  font-weight: 400;
  color: black;
}
.wrapColumnTwo {
  display: flex;
  flex: 3;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
  font-size: 18px;
  font-weight: 400;
  color: #87d6ea;
}
.wrapColumnThree {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-end;
  padding: 5px 0px;
  font-size: 30px;
  font-weight: 500;
  color: gray;
}

.wrapColumnThreeExp {
  font-size: 15px;
  color: black;
  margin-bottom: 3px;
}

.colorBlack {
  color: black !important;
}

.colorRed {
  color: red !important;
}

.wrapListExp {
  padding: 10px;
}

.wrapTitleExp {
  padding: 10px 10px 5px 10px;
}

.wrapLinkBlog {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 18px;
  color: #f971a5;
  text-decoration: underline;
  padding-right: 10px;
  font-size: 600;
}

.wrapNoHistory {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  padding: 30px 0px;
  color: gray;
}

.wrapColumnThreeTable {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 5px 0px;
  font-size: 30px;
  font-weight: 500;
  color: gray;
}
