@import "styles/_variables.scss";

.container {
  .header {
    padding: 20px 15px;
    background: #ffffff;
    border: 0.5px solid #d9d9d9;
    border-radius: 10px;
    .contTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #3b3b3b;
      }
      .addBtn {
        width: 177px;
      }
    }
  }
  .table {
    margin-top: 20px;
    background-color: $color-white;
  }
}

.subTitle {
  font-weight: 700;
  font-size: 18px;
  color: #3b3b3b;
}

.content {
  padding: 20px 15px;
  background: #ffffff;
  border: 0.5px solid #d9d9d9;
  border-radius: 10px;
}

.btnSubmit {
  width: 150px;
  margin: 30px auto 0px auto;
}

.row {
  display: flex;
  width: 40%;
  :global {
    .ant-form-item {
      margin-bottom: 0;
      margin-top: 5px;
    }
  }
}

.row + .row {
  margin-top: 20px;
}
