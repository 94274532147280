.labelStatus {
  color: #ffffff;
  padding: 3px 12px;
  border-radius: 15px;
  display: inline-block;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
