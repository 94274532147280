@import "/src/styles/variables";
@import "styles/_mixin.scss";

.header {
  // position: static;
  position: sticky;
  top: 0px;
  z-index: 1;
  width: 100%;
  height: $header-height;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 24px;
  background-color: white;
}
.headerLoginMobile {
  justify-content: space-between;
  padding: 5px 10px;
  height: unset
}

.languages {
  margin-right: 16px;
}

.email {
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.contLeft {
  display: flex;
}

.contLogo {
  width: 121px;
  height: 50px;
}

.tooltipContent {
  gap: 15px;
  display: flex;
  flex-direction: column;
  .wrapCloseTooltip {
    position: absolute;
    top: 5px;
    left: 5px;
  }
  .wrapLogo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 15px;
    .logo {
      align-self: center;
      object-fit: scale-down;
    }
  }
  .storeList {
    margin-left: 8px;
    font-size: 24px;
  }
  .logout {
    display: inline;
    font-size: 24px;
  }
}
.tooltipModal {
  position: fixed !important; 
  top: 0 !important;          
  left: 0 !important;        
  margin: 0 !important;      
  transform: none !important; 
  :global {
    .ant-tooltip-inner {
      min-width: 250px;
      padding-bottom: 40px;
    }
  }
}
