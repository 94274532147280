@import "../../../styles/variables";

.container {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .back {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #3b3b3b;
      svg {
        margin-right: 10px;
        cursor: pointer;
      }
    }
    .actions {
      display: flex;
      .saveBtn {
        margin-right: 10px;
        width: 150px;
      }
    }
  }
  .content {
    margin-top: 20px;
    background: #fff;
    border: 0.5px solid #d9d9d9;
    border-radius: 10px;
    padding: 20px 15px;
    display: flex;
    align-items: flex-start;
    .settingIcon {
      flex: 1;
      padding-left: 30px;
      border-left: 1px solid #d9d9d9;
      .iconTitle {
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        color: #3b3b3b;
      }
      .showAvatar {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        text-align: center;
      }
      .iconAction {
        display: flex;
        align-items: center;
        justify-content: center;
        .deleteIcon,
        .uploadIcon {
          width: 150px;
        }
        .deleteIcon {
          margin-right: 10px;
        }
      }
      .iconDes {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #909090;
      }
    }

    .storeInfo {
      flex: 1;
      margin-right: 30px;
      .infoTitle {
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        color: #3b3b3b;
      }
      .infoRow {
        margin-top: 20px;
        display: flex;

        :global {
          .ant-form-item {
            margin-bottom: 0;
            margin-top: 5px;
          }
          .ant-input {
            padding: 12px 10px !important;
          }
        }
      }
      .infoRowPassword {
        margin-top: 20px;
        display: flex;

        :global {
          .ant-form-item {
            margin-bottom: 0;
            margin-top: 5px;
          }
          .ant-input {
            padding: 12px 0px !important;
          }
        }
      }

      .infoRowStartEndTime {
        @extend .infoRow;
        align-items: flex-start;
      }

      .iconTimeDivider {
        display: flex;
        flex-direction: column;
        margin: 0 8px;
        padding-top: 40px;
        justify-content: center;
        align-items: center;
      }
    }

    .uploadIconText {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: #3b3b3b;
    }

    .videoDes {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #909090;
      margin-top: 4px;
    }

    .btnSubmit {
      width: 150px;
      margin: 30px auto 0 auto;
    }

    .timeDes {
      margin-top: 10px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #3b3b3b;
    }
  }
}

.rowEnd {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  &__other {
    margin-left: 20px;
  }
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.avatarWrongFormat {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid $color-scarlet;
  margin-bottom: 10px;
  overflow: hidden;
}

.txtError {
  color: $color-red;
  font-size: 12px;
}

.requiredIcon {
  @extend .txtError;
  margin-left: 5px;
  vertical-align: top;
}

.contForms {
  display: flex;
  width: 100%;
}

.imgWrapper {
  position: relative;
  width: 100px;
  height: 100px;
  margin-right: 16px;
  margin-bottom: 8px;
  &__tiff {
    @extend .imgWrapper;
    :global {
      #tiff-container {
        overflow: hidden;
        border-radius: 50%;
        border-width: 1px;
        border-color: $color-silver;
        border-style: solid;
        #tiff-inner-container {
          width: 100px;
          height: 100px;
        }
      }
    }
  }
}

.uploadIconCreate {
  width: 310px;
}

.suffix {
  position: absolute;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  top: 12px;
  right: 10px;
  color: #3b3b3b;
}

.customNumeric {
  height: 46px !important;
  padding: 12px 11px !important;
  border: 1px solid #d9d9d9 !important;
}

.notePhone {
  color: $color-gray;
  font-size: 12px;
  font-weight: 300;
  margin-top: 3px;
}
