.btnSubmit {
  width: 150px;
  margin: 30px auto 0px auto;
}

.row {
  display: flex;
  :global {
    .ant-form-item {
      margin-bottom: 0;
      margin-top: 5px;
    }
  }
}

.row + .row {
  margin-top: 20px;
}