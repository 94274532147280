.container {
  .userHeader {
    background: #FFFFFF;
    border: 0.5px solid #D9D9D9;
    border-radius: 10px;
    padding: 20px 15px;
    .topHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #3B3B3B;
    }
    .addStoreBtn {
      width: 177px;
    }
  }
  .storeTable {
    margin-top: 20px;
  }
}