.textEditor {
  :global {
    .ck.ck-content ul {
      padding-left: 40px;
    }
    .ck.ck-content ol {
      padding-left: 40px;
    }
  }
  margin-top: 10px;
}
