@import "../../styles//variables";
@import "styles/_mixin.scss";

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 10px 0;
  &--isWrapped {
    flex-wrap: wrap;
  }
}

.imgWrapper {
  position: relative;
  width: 74px;
  height: 74px;
  margin-right: 16px;
  margin-bottom: 8px;
  &__tiff {
    @extend .imgWrapper;
    :global {
      #tiff-container {
        overflow: hidden;
        border-radius: 6px;
        border-width: 1px;
        border-color: $color-silver;
        border-style: solid;
        #tiff-inner-container {
          width: 74px;
          height: 74px;
        }
      }
    }
  }
  &__tiffError {
    @extend .imgWrapper;
    :global {
      #tiff-container {
        overflow: hidden;
        border-radius: 6px;
        border-width: 1px;
        border-color: $color-scarlet;
        border-style: solid;
        #tiff-inner-container {
          width: 74px;
          height: 74px;
        }
      }
    }
  }
}

.imgCont {
  border-radius: 6px;
  border-width: 1px;
  border-color: $color-silver;
  border-style: solid;
  &__error {
    @extend .imgCont;
    border: 2px solid $color-scarlet !important;
  }
}

.imgContClose {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  z-index: 1;
}

.imgPlayPause {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 50%;
  z-index: 1;
}

.contDocument {
  width: 100%;
  display: flex;
  // border: 1px dashed;
  position: relative;
  padding: 5px;
  border-radius: 15px;
  margin-bottom: 15px;
  &__error {
    @extend .contDocument;
    border: 2px solid $color-scarlet;
  }
}
.txtDocument {
  max-width: 33vw;
  word-wrap: break-word;
  margin-left: 10px;
  margin-right: 12px;
  width: 70%;
}

.wrapListBottom {
  display: flex;
  position: absolute;
  right: 0px;
  @include respond(mobile-l) {
    position: relative
  }
}

.contImage {
  width: 100%;
  display: flex;
  // border: 1px dashed;
  position: relative;
  padding: 5px;
  border-radius: 15px;
  margin-bottom: 0px;
}
