.container {
  .header {
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 0.5px solid #d9d9d9;
    border-radius: 10px;
    padding: 15px;

    .back {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #3b3b3b;
      svg {
        margin-right: 10px;
        cursor: pointer;
      }
    }
    .title {
      font-weight: 700;
      font-size: 22px;
      line-height: 21px;
      margin-left: 10px;
    }
  }
  .body {
    margin-top: 15px;
    padding: 20px;
    border: 0.5px solid #d9d9d9;
    border-radius: 10px;
    background: #fff;
    .sendBtn {
      width: 165px;
      margin-top: 16px;
    }
  }
}

.detailLastRow {
  margin-top: 20px;
  width: 50%;
  .profileLabel {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #3b3b3b;
  }
  .profileContainer {
    margin-top: 6px;
    display: flex;
    .rowItem {
      margin-right: 8px;
      .img {
        width: 48px;
        height: 64.656px;
        border-radius: 5px;
      }
    }
  }
}
