@import "/src/styles/variables";

.wrapStatus {
  padding: 5px 10px;
  border-radius: 5px;
  width: 105px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapLink {
  // text-decoration: underline;
  color: $color-seagull;
  cursor: pointer;
}

.wrapItemTable {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapItemInvoice {
  border: 1px black solid;
  padding: 3px 10px;
  margin: 0px 8px;
  cursor: pointer;
}

.priceCol {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}
