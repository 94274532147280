.image {
  width: 100%;
}

.crop {
  width: 100%;
}

.btn_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn {
  margin-top: 10px;
  width: 150px;
}

.cropContainer {
  position: relative;
  width: 100%;
  height: 40vh;
}
