@import "styles/_variables.scss";

.container {
  .headerContainer {
    padding: 20px 15px;
    background: #ffffff;
    border: 0.5px solid #d9d9d9;
    border-radius: 10px;
    .postHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #3b3b3b;
      }
      .addGroupBtn {
        min-width: 150px;
      }
    }
  }
  .storeTable {
    margin-top: 20px;
  }
}
