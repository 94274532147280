.container {
  padding: 6px 0px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    border: 0.5px solid #D9D9D9;
    border-radius: 10px;
    padding: 20px;
    .back {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #3B3B3B;
      svg {
        margin-right: 10px;
        cursor: pointer;
      }
    }
    .actions {
      display: flex;
      .saveBtn {
        margin-right: 10px;
        width: 150px;
      }
    }
  }
  .content {
    margin-top: 8px;
    padding: 15px;
    background: #FFFFFF;
    border: 0.5px solid #D9D9D9;
    border-radius: 10px;
    .row {
      :global {
        .ant-checkbox-group {
          width: 100%;
          flex-wrap: wrap;
          .ant-checkbox-wrapper {
            width: 25%;
            margin-left: 0;
            margin-top: 20px;
          }
        }
      }
      display: flex;
      :global {
        .ant-form-item {
          margin-bottom: 0;
          margin-top: 5px;
        }
      }
      .roleName {
        width: 400px;
        flex: none;
      }
    }
    .row + .row {
      margin-top: 30px;
    }
  }
}