.loading {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0.8;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}
