@import "/src/styles/variables";

.wrapStatus {
  padding: 5px 10px;
  border-radius: 5px;
  width: 105px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapLink {
  // text-decoration: underline;
  color: $color-seagull;
  cursor: pointer;
}
